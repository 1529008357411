/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
  Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.6;
  font-size: 18px;
}
main {
  /*height: 70% !important;*/

}
.rendering{
  /*height: 60vh;*/
}

@layer base {
  .input_number_no_arrow::-webkit-inner-spin-button,
  .input_number_no_arrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
/** {*/
/*    box-sizing: border-box;*/
/*}*/

/*a {*/
/*    color: #0070f3;*/
/*    text-decoration: none;*/
/*}*/

/*a:hover {*/
/*    text-decoration: underline;*/
/*}*/

/*img {*/
/*  max-width: 100%;*/
/*  display: block;*/
/*}*/

/*#react-select-3-listbox{*/
/*  position: relative !important;*/
/*  !* CSS goes here *!*/
/*}*/

/*.rdrMonth{*/
/*  max-width: 100%;*/
/*}*/